<div class="filterContainerInside">
  <div class="containerHeader row"
    [ngStyle]="{ 'background-color': applyThemeService.$themeColor,  color: applyThemeService.$textColor }">
    <span class="reviewFilterOptions inline-block col-md-6" style="width: 100%; position: relative;">
      <div class="col-md-2" style="margin-left: -15px;">
        <span (click)="openCloseReviewFilter()"> {{ filterNameText | translate }} </span>
      </div>

      <div class="col-md-4" style="margin-left: -5px; margin-top: 3px;">
        <angular2-multiselect *ngIf="!isModeratorUserRole && isReviewFilterOpen === true" [data]="savedStates"
          [(ngModel)]="selectedSavedStates" [settings]="savedStatesDropdownSettings"
          (onSelect)="onChangeBookmark($event)" (onDeSelect)="onBookmarkDeSelect($event)">
        </angular2-multiselect>
      </div>

      <span class="rightAlignSaveFilters">
        <button *ngIf="isReviewFilterOpen === true" class="saveFilterButtonClass saveStateColor" [ngStyle]="{
            'background-color': applyThemeService.$themeColor,
            color: applyThemeService.$textColor
          }" type="button" name="saveFilterButton" (click)="resetAllFilter(true)">
          <label class="saveFilterLabel" for="saveFilterButton" [ngStyle]="{ color: applyThemeService.$textColor }">
            {{ "BUTTONS.Clear All" | translate }}
          </label>
        </button>

        <button class="saveFilterButtonClass saveStateColor" [ngStyle]="{
            'background-color': applyThemeService.$themeColor,
            color: applyThemeService.$textColor
          }" type="button" name="saveFilterButton" (click)="onSaveFilter()" *ngIf="isReviewFilterOpen === true">
          <label class="saveFilterLabel" for="saveFilterButton" [ngStyle]="{ color: applyThemeService.$textColor }">{{
            "BUTTONS." +saveState | translate }}</label>
        </button>
        <button type="button" name="deleteFilterButton" class="saveFilterButtonClass saveStateColor"
          [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }"
          (click)="onDeleteFilter()" *ngIf="isReviewFilterOpen === true" [disabled]="selectedSavedStates.length == 0"
          [class.disabled]="selectedSavedStates.length == 0">
          <label class="saveFilterLabel" for="deleteFilterButton" [class.disabled]="selectedSavedStates.length == 0"
            [ngStyle]="{ color: applyThemeService.$textColor }">{{
            "BUTTONS.Delete View" | translate }}
          </label>
        </button>
      </span>

      <span class="paddingArrow" (click)="openCloseReviewFilter()"
        [ngStyle]="{ transform: isReviewFilterOpen == true ? 'rotate(90deg)' : 'rotate(0deg)' }">
        <span class="icon icon-right-arrow"></span>
      </span>
    </span>
  </div>

  <div [class.hidden]="!isReviewFilterOpen" class="row" #reviewFilterCollapsible>
    <div class="col-md-12">
      <div *ngIf="filterAccess.BrandName" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Brand Name" | translate }}
          <mat-icon class="info" matTooltip="{{'Brand-Name-Tooltip' | translate }}" matTooltipPosition="after"
            aria-label="Brand-Name-Tooltip">info
          </mat-icon> :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [settings]="brandDropdownSettings" [data]="brandNameList"
            [(ngModel)]="brandSelectedItems" (onSelect)="onBrandNameSelect($event)"
            (onDeSelect)="onBrandNameDeSelect($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.BrandSite" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Brand Site" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [settings]="marketDropdownSettings" [data]="arrMarketList"
            [(ngModel)]="marketListSelectedItems" (ngModelChange)="onMarketExtraction($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.OpenSearch" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Open Search" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <div class="dateRangeInput">
            <input [(ngModel)]="filters.openSearch" type="text" class="" id="searchinput" #search
              (keyup.enter)="searchedText()" [placeholder]="'Search' | translate"
              style="all: unset; width: calc(100% - 47px);" />
            <span id="search-icon">
              <img *ngIf="search.value" class="deleteIcon" src="/assets/images/delete_visible.png"
                (click)="clearSearch()" alt="delete" />
              <img *ngIf="!search.value" class="deleteIcon" src="/assets/images/delete_invisible.png" alt="-" />
              <img class="searchIcon" src="/assets/images/search.png" (click)="searchedText()" alt="search" />
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.Category" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Category" | translate }} :
        </div>
        <div class="col-md-8 filter" *ngIf="categoryDropdownSettings">
          <angular2-multiselect [data]="categoryList" [(ngModel)]="selectedCategory"
            [settings]="categoryDropdownSettings" (onSelect)="onCategorySelect($event)"
            (onDeSelect)="onCategoryDeSelect($event)" (onSelectAll)="onCategorySelectAll($event)"
            (onDeSelectAll)="onCategoryDeSelectAll($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.Product" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Product" | translate }} :
        </div>
        <div class="col-md-8 filter" id="dateRangePickerParentRef"
          [class.dateRangePickerParentRef]="datePickerPosition == 'right'">
          <angular2-multiselect [data]="productList" [(ngModel)]="selectedProduct" [settings]="productDropdownSettings"
            (onSelect)="onProductSelect($event)" (onDeSelect)="onProductDeSelect($event)"
            (onSelectAll)="onProductSelectAll($event)" (onDeSelectAll)="onProductDeSelectAll($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.Date" class="alignDiv" id="dateRangePickerParentRef">
        <div class="col-md-4 filterlabel">{{ "Date" | translate }} :</div>
        <div class="col-md-8 filter">
          <input type="text" #datePickerContainerRef [ngClass]="filterAccess.Date ? 'dateRangeInput' : 'disableModule'"
            id="dateHandle" name="daterangeInput" daterangepicker autocomplete="off" [options]="datePickerOptions"
            [(ngModel)]="selectedDateRange" (selected)="onSelectedDate($event, dateRange)"
            (applyDaterangepicker)="onApplyCalender($event, dateRange)"
            (cancelDaterangepicker)="onCalendarCanceled($event)"
            placeholder="{{ 'Date' | translate }} {{ 'Range' | translate }}"
            [disabled]="filterAccess.Date ? false : true" />
        </div>
      </div>

      <div *ngIf="filterAccess.StarProduct" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Star Product" | translate }} :
        </div>
        <div class="col-md-8 filter" id="dateRangePickerParentRef"
          [class.dateRangePickerParentRef]="datePickerPosition == 'left'">
          <angular2-multiselect [data]="starProduct" [(ngModel)]="tempSelectedStarProduct"
            [settings]="starProductDropdownSettings" (onSelect)="onStarProductSelected($event)"
            (onDeSelect)="starProductRemoved($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.ProductStatus" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Product Status" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="productStatus" [(ngModel)]="selectedStarProduct"
            [settings]="productStatusDropdownSettings" (onSelect)="onProductStatusSelected($event)"
            (onDeSelect)="productStatusRemoved($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.ModerationCode" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Moderation Code" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="moderationCodes" [(ngModel)]="tempSelectedModerationCodes"
            [settings]="moderationCodesDropdownSettings" (onSelect)="onModerationCodeSelected($event)"
            (onDeSelect)="moderationCodeRemoved($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.ReviewSource" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Review Source" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="sourceList" [(ngModel)]="selectedSource" [settings]="sourceDropdownSettings"
            (onSelect)="onSourceSelect($event)" (onDeSelect)="onSourceDeSelect($event)"
            (onSelectAll)="onSourceSelectAll($event)" (onDeSelectAll)="onSourceDeSelectAll($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.ModerationStatus" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Moderation Status" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="reviewStatuses" [(ngModel)]="selectedReviewStatus"
            [settings]="moderationStatusDropdownSettings" (onSelect)="onReviewStatusSelected($event)"
            (onDeSelect)="reviewStatusRemoved($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.Campaign" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Campaign" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="campaigns" [(ngModel)]="campaignsSelectedItem" [settings]="campaignSettings"
            (onSelect)="onCampaignSelected($event)" (onDeSelect)="onCampaignItemDeSelect($event)"
            (onSelectAll)="onCampaignSelectAll($event)" (onDeSelectAll)="onCampaignDeSelectAll($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.ProductGrouping" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Product Grouping" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="productGroupTypeList" [(ngModel)]="tempSelectedProductGroupStatus"
            (onSelect)="onProductGroupTypeSelect($event)" (onDeSelect)="onProductGroupTypeDeSelect($event)"
            [settings]="productGroupTypeSetting">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.StarRating" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Star Rating" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="starRatings" [(ngModel)]="selectedRatings" [settings]="ratingsDropdownSettings"
            (onSelect)="onStarRatingChange()" (onDeSelect)="onStarRatingChange()" (onSelectAll)="onStarRatingChange()"
            (onDeSelectAll)="onStarRatingChange()">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.ReviewAnswers" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Answers to Reviews" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="reviewAnswers" [(ngModel)]="reviewAnswersChoice"
            [settings]="reviewAnswersDropdownSettings" (onSelect)="onReviewAnswerSelected($event)"
            (onDeSelect)="reviewAnswerRemoved($event)">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.Badges" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Badges" | translate }} :
        </div>
        <div class="col-md-8 filter">
          <angular2-multiselect [data]="badgeTemplates" [(ngModel)]="selectedBadgeTemplate"
            [settings]="badgeDropdownSettings" (onSelect)="onBadgeTemplateSelect($event)"
            (onDeSelect)="onBadgeTemplateRemove()">
          </angular2-multiselect>
        </div>
      </div>

      <div *ngIf="filterAccess.ReviewCount" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Review" | translate }} {{"Count" | translate }}
          <mat-icon class="info" matTooltip="{{'Review-Count-Tooltip' | translate }}" matTooltipPosition="after"
            aria-label="Review-Count-Tooltip">info
          </mat-icon> :
        </div>
        <div class="col-md-3 filter">
          <angular2-multiselect [data]="reviewOperators" [(ngModel)]="reviewOperatorSelectedItem"
            [settings]="reviewCountDropdownSettings" (onSelect)="onReviewOperatorSelected($event)"
            (onDeSelect)="onReviewOperatorDeSelect($event)">
          </angular2-multiselect>
        </div>
        <div class="col-md-3 filter">
          <input type="number" [min]="0"
            matTooltip="{{ filters.reviewOperator == 'all' ? ('Review-Count-tooltip' | translate) : '' }}"
            class="dateRangeInput P-8" type="number" [(ngModel)]="filters.reviewCount"
            [disabled]="filters.reviewOperator == 'all'" [class.disabled]="filters.reviewOperator == 'all'" />
        </div>
        <div class="col-md-2 filter">
          <button class="btn btn-primary customBtn MT-3" [ngStyle]="{
            'background-color': applyThemeService.$themeColor,
            color: applyThemeService.$textColor }" [disabled]="filters.reviewOperator == 'all'"
            [class.disabled]="filters.reviewOperator == 'all'" (click)="onSearchReviewCount()">Search</button>
        </div>
      </div>

      <div *ngIf="showReviewerField" class="alignDiv">
        <div class="col-md-4 filterlabel">
          {{ "Reviewer" | translate }} {{"Name" | translate }}
          <mat-icon class="info" matTooltip="{{'Reviewer-Name-tooltip' | translate }}" matTooltipPosition="after"
            aria-label="Reviewer-Name-tooltip message">info
          </mat-icon> :
        </div>
        <div class="col-md-8 filter">
          <div [ngClass]="this.reviewerName ? 'dateRangeInput P-5' : 'disableModule P-5'">
            <input [ngClass]="this.reviewerName ? 'filledreviewer_input' : 'defaultreviewer_input'"
              [(ngModel)]="reviewerName" type="text" [disabled]="true" />
            <span *ngIf="this.reviewerName">
              <img *ngIf="this.reviewerName" class="closBtnReview" src="/assets/images/delete_visible.png"
                (click)="onCloseReviewerData()" alt="delete" />
            </span>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="showSaveFilterName" class="overlayWrapper PF style-4">
      <div class="editProfileDetailView">
        <div class="row popupHeader"
          [ngStyle]="{'background-color': applyThemeService.$themeColor, 'color': applyThemeService.$textColor, 'margin-left':'0px', 'margin-right':'0px' }">
          <div class="col-md-5"></div>
          <div class="col-md-6">
            <span class="saveFilterTitleText"> {{ "BUTTONS.Save" | translate }} {{ "Filter" | translate }}</span>
          </div>
          <div class="col-md-1 closeSaveFilter">
            <span class="iconContainerSpan spanContainer" (click)="closeSaveFilterWindow()" [ngStyle]="{
            'background-image': 'url(/assets/images/close.png)'}"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-offset-1 col-md-10">
            <input class="saveFilterNameInput" type="text" [(ngModel)]="saveFilterByName"
              placeholder="{{'Enter'|translate}} {{'new'|translate}} {{'filter'|translate}} {{'template'|translate}} {{'name'|translate}}" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-7"></div>
          <div class="col-md-4">
            <button type="button" [disabled]="!saveFilterByName" class="btn btn-primary customBtn W-110" [ngStyle]="{
            'background-color': applyThemeService.$themeColor,
            color: applyThemeService.$textColor }" (click)="onSaveFilterState()">
              {{ "BUTTONS.Save" | translate }}
            </button>
            <button type="button" class="btn btn-default customBtnCancel W-110" (click)="closeSaveFilterWindow()">
              {{ "BUTTONS.Cancel" | translate }}
            </button>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>

  </div>
</div>